import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import "./Recipes.css";
import HomeSearch from "../Components/Screens/HomeSearch";
import NoMatchFound from "../Components/Screens/NoMatchFound";
import Loader from "../Components/Screens/Loader";
import ErrorFound from "../Components/Screens/ErrorFound";
import RecipeContext from "../Context/RecipeContext/recipeContext";

function Recipes(props) {
  let { categoryName, categoryId } = useParams();
  // const [categoryData, setcategoryData] = useState([]);
  // const [categoryError, setcategoryError] = useState("");
  const [recipeSearch, setrecipeSearch] = useState("");

  const {
    recipesData,
    setRecipesData,
    recipesDataError,
    setRecipesDataError,
    loading,
    setLoading,
  } = useContext(RecipeContext);

  const recipeSearchFun = (msg) => {
    setrecipeSearch(msg);
    console.log("parent ", recipeSearch);
  };

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}recipes/category/${categoryId}`)
      .then((res) => {
        res.json().then((result) => {
          setRecipesData(result);
          setLoading(false);
        });
      })
      .catch((err) => {
        setRecipesDataError(true);
        setLoading(false);
      });
  }, [
    recipesData,
    categoryId,
    setLoading,
    setRecipesData,
    setRecipesDataError,
  ]);

  return (
    <>
      <HomeSearch
        recipeSearchFun={recipeSearchFun}
        categoryName={categoryName}
      />
      <div className="recipes-container">
        {loading && <Loader />}
        <div className="row mb-5">
          {recipesData &&
            recipesData
              .filter((filteredCat) =>
                filteredCat.name
                  .toLowerCase()
                  .includes(recipeSearch.toLowerCase())
              )
              .map((item, index) => {
                return (
                  <div className="col-lg-4 col-md-6 col-sm-12 mb-3">
                    <Link
                      to={`/${categoryName}/${item.name}/${item._id}`}
                      className="category-card"
                    >
                      <div className="cards_item">
                        <div className="card">
                          <div className="card_image">
                            <img
                              src={
                                item.imageUrl.includes("http")
                                  ? item.imageUrl
                                  : `/images/categories/chinese.jpg`
                              }
                              alt="A side view of a plate of figs and berries."
                            />
                          </div>
                          <div className="card_content">
                            <h2 className="card_title">{item.name}</h2>
                            {item.isVeg && (
                              <div className="veg">
                                <i class="fa-sharp fa-solid fa-circle"></i>
                              </div>
                            )}
                            {!item.isVeg && (
                              <div className="nonveg">
                                <i class="fa-sharp fa-solid fa-circle"></i>
                              </div>
                            )}

                            <div className="card_text ">
                              <p className="">
                                {item.description.substring(0, 130)}...
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}

          {!loading &&
            !recipesDataError &&
            recipesData &&
            recipesData.filter((filteredCat) =>
              filteredCat.name
                .toLowerCase()
                .includes(recipeSearch.toLowerCase())
            ).length === 0 && (
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                <NoMatchFound />
              </div>
            )}

          {recipesDataError && (
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <ErrorFound />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Recipes;
