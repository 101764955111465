import React, { useEffect, useState } from "react";
import Loader from "../Components/Screens/Loader";
import "./Recipe.css";
import { useParams } from "react-router-dom";

function Recipe() {
  const [loading, setLoading] = useState(true);
  const [singleRecipeError, setSingleRecipeError] = useState(null);
  const [singleRecipeData, setSingleRecipeData] = useState([]);

  const getRatingHtml = (rating) => {
    let ratingHtml = [];
    let totalRating = 5;
    for (let i = 1; i <= totalRating; i++) {
      if (i <= rating)
        ratingHtml.push(<i key={i} className="fa-solid fa-star rated"></i>);
      else ratingHtml.push(<i key={i} className="fa-solid fa-star"></i>);
    }

    return ratingHtml;
  };

  const { recipeId } = useParams();

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}recipes/${recipeId}`)
      .then((res) => {
        res.json().then((result) => {
          setSingleRecipeData(result);
          setLoading(false);
        });
      })
      .catch((err) => {
        setSingleRecipeData(true);
        setLoading(false);
      });
  }, []);

  return (
    <div className="recipe-container">
      {loading && <Loader />}
      {!loading && singleRecipeData && (
        <>
          <div className="container-1">
            <div className="recipe-col-1">
              <img
                src={
                  singleRecipeData.imageUrl.includes("http")
                    ? singleRecipeData.imageUrl
                    : `/images/categories/chinese.jpg`
                }
                alt=""
              />
            </div>
            <div className="recipe-col-2">
              <div className="recipeheading">
                <h1>{singleRecipeData.name}</h1>
                {/* <div className="veg recipe-veg">
              <i class="fa-sharp fa-solid fa-circle"></i>
            </div> */}
                {/* <div className="nonveg recipe-veg">
              <i class="fa-sharp fa-solid fa-circle"></i>
            </div> */}
                <hr className="recipe-heading-hr" />
              </div>
              <div className="recipe-details">
                <div className="recipe-rating recipe-details-child">
                  <p>Rating</p>
                  {getRatingHtml(singleRecipeData.rating)}
                </div>
                <div className="  recipe-details-child">
                  <p>Veg</p>
                  {singleRecipeData.isVeg && (
                    <div className="veg recipe-veg">
                      <i className="fa-sharp fa-solid fa-circle"></i>
                    </div>
                  )}
                  {!singleRecipeData.isVeg && (
                    <div className="nonveg recipe-nonveg">
                      <i className="fa-sharp fa-solid fa-circle"></i>
                    </div>
                  )}
                </div>
                <div className="recipe-cat recipe-details-child">
                  <p>Category</p>
                  <h5>{singleRecipeData.category}</h5>
                </div>
                <div className="recipe-video recipe-details-child">
                  <p>Videos</p>
                  <a href="">
                    <i className="fa-brands fa-square-instagram"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-square-facebook"></i>
                  </a>
                  <a href="">
                    <i className="fa-brands fa-square-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="container-2">
            <h2>About</h2>
            <p>{singleRecipeData.description}</p>
          </div>
          <div className="container-3">
            <h2>Ingredients</h2>
            <div className="ing">
              {singleRecipeData.ingredients.split("*").map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
          </div>
          <div className="container-4">
            <h2>Method</h2>

            <ul>
              {singleRecipeData.recipeMethod.split("*").map((item, index) => (
                <li key={index}>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

export default Recipe;
