import React from "react";
import CategoryContextProvider from "./CategoryContextProvider";
import { Outlet } from "react-router-dom";

function CategoryContextLayout() {
  return (
    <CategoryContextProvider>
      <Outlet />
    </CategoryContextProvider>
  );
}

export default CategoryContextLayout;
