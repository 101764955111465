import React, { useEffect, useState } from "react";
import CategoryContext from "./categoryContext";

function CategoryContextProvider({ children }) {
  const [homeData, sethomeData] = useState([]);
  const [loading, setloading] = useState(true);
  const [homeError, sethomeError] = useState(false);

  useEffect(() => {
    fetch(`${process.env.REACT_APP_API_URL}categories`)
      .then((res) => {
        res.json().then((result) => {
          sethomeData(result);
          setloading(false);
        });
      })
      .catch((err) => {
        sethomeError(true);
        setloading(false);
      });
  }, []);

  return (
    <CategoryContext.Provider value={{ homeData, homeError, loading }}>
      {children}
    </CategoryContext.Provider>
  );
}

export default CategoryContextProvider;
