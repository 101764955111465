import React, { useContext, useEffect, useState } from "react";
import "./Home.css";
import HomeSearch from "../Screens/HomeSearch";
import { Link } from "react-router-dom";
import NoMatchFound from "../Screens/NoMatchFound";
import Loader from "../Screens/Loader";
import ErrorFound from "../Screens/ErrorFound";
import CategoryContext from "../../Context/CategoryContext/categoryContext";

function Home() {
  const [categoryData, setCategoryData] = useState([]);
  const [categorySearchStr, setcategorySearchStr] = useState("");

  const { homeData, loading, homeError } = useContext(CategoryContext);

  // function for searching categories in the home component
  const categoryFun = (msg) => {
    setcategorySearchStr(msg);
  };

  useEffect(() => {
    setCategoryData(homeData);
  }, [homeData]);

  return (
    <>
      <HomeSearch categoryFun={categoryFun} />

      <div className="home-container">
        {loading && <Loader />}
        <div className="row mb-5">
          {categoryData &&
            categoryData
              .filter((filteredCat) =>
                filteredCat.name
                  .toLowerCase()
                  .includes(categorySearchStr.toLowerCase())
              )
              .map((item, index) => {
                return (
                  <div className="col-lg-3 col-md-6 col-sm-12 mb-3" key={index}>
                    <Link
                      to={`${item.name}/${item._id}`}
                      className="category-card"
                    >
                      <div className="cards_item">
                        <div className="card">
                          <div className="card_image">
                            <img
                              src={
                                item.imageUrl
                                  ? item.imageUrl
                                  : `/images/categories/category-dummy.jpg`
                              }
                              alt="A side view of a plate of figs and berries."
                            />
                          </div>
                          <div className="card_content">
                            <h2 className="card_title">{item.name}</h2>
                            <div className="card_text">
                              <p>{item.description}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              })}
          {/* no matching found */}
          {!loading &&
            !homeError &&
            categoryData &&
            categoryData.filter((filteredCat) =>
              filteredCat.name
                .toLowerCase()
                .includes(categorySearchStr.toLowerCase())
            ).length === 0 && (
              <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                <NoMatchFound />
              </div>
            )}

          {/* if error occur */}
          {homeError && (
            <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
              <ErrorFound />
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Home;
