import React from "react";
import { Outlet } from "react-router-dom";
import RecipeContextProvider from "./RecipeContextProvider";

function RecipeContextLayout() {
  return (
    <RecipeContextProvider>
      <Outlet />
    </RecipeContextProvider>
  );
}

export default RecipeContextLayout;
