import "./App.css";
import Footer from "./Components/Footer/Footer";
import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Recipes from "./Recipes/Recipes";
import PageNotFound from "./PageNotFound/PageNotFound";
import Recipe from "./Recipe/Recipe";
import CategoryContextLayout from "./Context/CategoryContext/CategoryContextLayout";
import CategoryContextProvider from "./Context/CategoryContext/CategoryContextProvider";
import RecipeContextLayout from "./Context/RecipeContext/RecipeContextLayout";
import { useEffect } from "react";

function App() {
  // const [backendUrl, setbackendUrl] = useState("http://localhost:5000/"); // or http://localhost:5000/
  // or "https://homdemadesnacks-backend.onrender.com/"

  useEffect(() => {
    console.log(process.env.REACT_APP_API_TEST);
  }, []);

  return (
    <Router>
      <CategoryContextProvider>
        <div className="navbar-container">
          <Navbar />
        </div>
      </CategoryContextProvider>

      <div className="container mb-5 mt-5">
        <Routes>
          <Route element={<CategoryContextLayout />}>
            <Route exact path="/" element={<Home />} />
          </Route>

          <Route exact path="/about" element={<About />}></Route>
          <Route exact path="#contact-us" element={<Contact />}></Route>
          <Route element={<RecipeContextLayout />}>
            <Route path="/:categoryName/:categoryId" element={<Recipes />} />
          </Route>

          <Route
            path="/:categoryName/:recipeName/:recipeId"
            element={<Recipe />}
          />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
