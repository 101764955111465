import React from "react";
import "./ErrorFound.css";

function ErrorFound() {
  return (
    <div className="error-found">
      <h3>Something Went Wrong</h3>
    </div>
  );
}

export default ErrorFound;
