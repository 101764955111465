import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import CategoryContext from "../../Context/CategoryContext/categoryContext";

function Navbar() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [activeItem, setActiveItem] = useState("home");

  const { homeData } = useContext(CategoryContext);

  const toggleNav = () => {
    setIsNavOpen(!isNavOpen);
  };

  const closeNav = () => {
    setIsNavOpen(false);
  };

  return (
    <div className="container">
      <nav className="navbar navbar-expand-lg custom-navbar-main-div">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img src="/images/Logo.jpg" alt="" className="header-img" />
          </a>
          <button
            className={`navbar-toggler ${isNavOpen ? "" : "collapsed"}`}
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded={isNavOpen ? "true" : "false"}
            aria-label="Toggle navigation"
            onClick={toggleNav}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            className={`collapse navbar-collapse custom-nav-align ${
              isNavOpen ? "show" : ""
            }`}
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 main-ul">
              <li className="nav-item">
                <Link className={`nav-link`} to="/" onClick={closeNav}>
                  Home
                </Link>
              </li>

              <li className="nav-item dropdown">
                <a
                  className="nav-link dropdown-toggle"
                  href="#"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  Categories
                </a>
                <ul className="dropdown-menu">
                  {homeData &&
                    homeData.map((item, index) => {
                      return (
                        <li key={index}>
                          <Link
                            className="dropdown-item"
                            to={`${item.name}/${item._id}`}
                          >
                            {item.name}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
              </li>
              <li className="nav-item">
                <Link className={`nav-link`} to="/about" onClick={closeNav}>
                  About
                </Link>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="#contact-us" onClick={closeNav}>
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
