import React, { useState } from "react";
import RecipeContext from "./recipeContext";

function RecipeContextProvider({ children }) {
  // const [categoryId, setCategoryId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [recipesData, setRecipesData] = useState([]);
  const [recipesDataError, setRecipesDataError] = useState(null);

  return (
    <RecipeContext.Provider
      value={{
        recipesData,
        setRecipesData,
        recipesDataError,
        setRecipesDataError,
        loading,
        setLoading,
      }}
    >
      {children}
    </RecipeContext.Provider>
  );
}

export default RecipeContextProvider;
