import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

function Footer() {
  return (
    <div>
      <footer className="footer-distributed">
        <div className="container">
          <div className="footer-left">
            <Link className="navbar-brand" to="/">
              <img src="/images/Logo.jpg" alt="" className="footer-img" />
            </Link>
            <p className="footer-links">
              <Link to="/" className="link-1">
                Home
              </Link>

              <Link to="/about">About Us</Link>

              <Link to="/contact">Contact Us</Link>
            </p>

            <p className="footer-company-name">Home Made Snacks © 2023</p>
          </div>

          <div className="footer-center" id="contact-us">
            <div>
              <i className="fa fa-map-marker"></i>
              <p>
                <span>Noida Sector 31</span>Gautam Buddha Nagar (U.P)
              </p>
            </div>

            <div>
              <i className="fa fa-phone"></i>
              <p>+91-9871750805</p>
            </div>

            <div>
              <i className="fa fa-envelope"></i>
              <p>
                <a href="mailto:support@company.com">
                  homemadesnacks01@gmail.com
                </a>
              </p>
            </div>
          </div>

          <div className="footer-right">
            <p className="footer-company-about">
              <span>About Us</span>
              At homemadesnacks, we're all about celebrating the joy of crafting
              mouthwatering snacks from the heart of our kitchen to your screen.
              Our story begins with a shared love for homemade goodness.
              Together, we're on a mission to redefine snacking, one homemade
              delight at a time.
            </p>

            <div className="footer-icons">
              <a
                href="https://www.facebook.com/homemadesnacks01"
                target="_blank"
              >
                <i class="fa-brands fa-square-facebook"></i>
              </a>
              <a
                href="https://www.instagram.com/homemadesnacks01/"
                target="_blank"
              >
                <i class="fa-brands fa-square-instagram"></i>
              </a>
              <a href="#" target="_blank">
                <i class="fa-brands fa-square-youtube"></i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
