import React from "react";
import './About.css';

function About() {
  return (
    <>
      <div className="page-heading">
        <h1>About Us</h1>
      </div>
      <div className="page-content">
        <p>At homemadesnacks, we're all about celebrating the joy of crafting mouthwatering snacks from the heart of our kitchen to your screen. Our story begins with a shared love for homemade goodness. Together, we're on a mission to redefine snacking, one homemade delight at a time.</p>
        <p>At HomemadeSnacks, our mission is clear: we aim to transform the way you think about snacking. We understand that snacking is an integral part of our lives, whether it's a quick bite on a busy day or a delightful treat to savor during leisure moments. We want to make those snacking moments more special, more memorable, and above all, more delicious.</p>
      </div>
    </>
  );
}

export default About;
