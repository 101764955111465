import React from "react";
import "./NoMatchFound.css";
function NoMatchFound() {
  return (
    <div className="no-match-found">
      <h3>No Match Found</h3>
    </div>
  );
}

export default NoMatchFound;
