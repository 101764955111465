import React from "react";
import "./HomeSearch.css";

function HomeSearch(props) {
  const categoryValue = (event) => {
    if (props.recipeSearchFun) {
      props.recipeSearchFun(event.target.value);
    } else props.categoryFun(event.target.value);
  };

  return (
    <div className="home-search-div">
      <div className="home-heading">
        {props.recipeSearchFun ? (
          <>
            <h1 className="heading-big">
              Find Your {props.categoryName} Recipe
            </h1>
            <h2 className="heading-small">
              Find Your {props.categoryName} Recipe
            </h2>
          </>
        ) : (
          <>
            <h1 className="heading-big">Find Recipes by Category</h1>
            <h2 className="heading-small">Find Recipes by Category</h2>
          </>
        )}
      </div>
      <div className="category-search">
        <input
          type="text"
          placeholder="Search your recipe category..."
          onChange={categoryValue}
        />
      </div>
    </div>
  );
}

export default HomeSearch;
